.navigation-bar-container{
    font-family: 'Lora', serif;
    backdrop-filter: blur(3px);
    background-color: rgb(30, 31, 38, 0.4);
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100vw;
    height: 15vh;
    box-shadow: 0 4px 2px -2px rgb(30, 31, 38, 0.6);
    overflow: hidden;
    transition: height 0.1s ease-in;
    z-index: 5;
    @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
        height: 10vh;
    }

    .nav-bar-logo-container{
        height: 90%;
        width: calc(100vh * 0.14);
        background-size: calc(100vh * 0.14) auto;
        background-repeat: no-repeat;
        background-position-x: 0;
        background-position-y: 0;
        margin-left: 20px;
        @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
            background-size: calc(100vh * 0.09) auto;
        }
    }

    .links-container{
        display: flex;
        @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
            display: none;
        }
        text-align: center;
        padding-right: 50px;
        height: 100%;
    }

    .links-container-mobile{
        display: none;
        @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
            display: block;
        }
        img{
            z-index: 10;
            margin-left: auto;
            transform: scale(0.3);
            position: relative;
        }
    }

}

.invisible-nav-bar{
    height: 0;
    transition: all 0.3s ease-in;
}

.nav-bar-placeholder{
    height: 15vh;
    width: 100%;
    @media (max-width:481px)  { /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide. */ 
        height: 10vh;
    }
}

.button-menu{
    z-index: 10;
    width: 100vw;
    background-color: #1E1F26;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    img{
        z-index: 10;
        right: 0;
        top: 10px;
        transform: scale(0.3);
        position: absolute;
    }

    .links{
        margin: auto 0;
    }
}
.visible{
    height: 100vh;
    transition: all 0.3s ease-in-out;
}
.invisible{
    height: 0;
    transition: all 0.3s ease-in-out;
}

